import { useHttpMutation } from '@/shared/hooks';

import { OTPChannel, Start2faStepResponse } from '../../../shared/types';

interface Start2faStepPayload {
  channel?: OTPChannel;
}

export const useStart2faStep = (
  options?: Parameters<
    typeof useHttpMutation<Start2faStepPayload, Start2faStepResponse>
  >[0],
) => {
  return useHttpMutation<Start2faStepPayload, Start2faStepResponse>({
    method: 'POST',
    // api: mockApi,
    ...options,
  });
};
