import { SVGProps } from 'react';

export const CautionDangerIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="197"
      height="198"
      viewBox="0 0 197 198"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Caution 1" clipPath="url(#clip0_6067_9478)">
        <path
          id="Vector"
          d="M2.05897 169.455C7.54108 164.763 44.1383 164.207 107.236 165.646C214.276 168.092 115.484 176.087 128.709 176.516C138.315 176.831 206.312 178.792 195.369 183.76C181.476 190.07 62.2271 187.471 48.6605 185.059C35.0939 182.648 46.9042 178.605 63.2297 178.784C72.4945 178.886 75.1823 176.066 36.4164 175.591C23.2337 175.429 -3.89954 174.546 2.05186 169.455H2.05897Z"
          fill="#010205"
        />
        <path
          id="Vector_2"
          d="M175.842 171.246C182.257 171.516 190.873 172.455 188.756 175.099C186.064 178.462 162.971 177.076 160.342 175.789C157.714 174.502 162.431 170.67 175.849 171.239L175.842 171.246Z"
          fill="#010205"
        />
        <path
          id="Vector_3"
          d="M53.7047 14.3686L44.7259 14.5535C43.5183 14.5748 42.5025 15.4635 42.3107 16.658L18.7839 164.555C18.5566 165.97 19.6505 167.257 21.0854 167.257H39.2775C40.3999 167.257 41.3659 166.454 41.5719 165.345L44.804 147.613C45.0029 146.518 45.9477 145.715 47.0559 145.7L81.0959 145.132C82.4242 145.11 83.4684 146.248 83.3406 147.57L81.6499 164.818C81.5221 166.127 82.545 167.257 83.8591 167.257H99.2951C100.403 167.257 101.341 166.44 101.49 165.338L121.906 16.139C122.161 14.262 120.677 12.5983 118.78 12.6481L53.7118 14.3686H53.7047Z"
          fill="black"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M21.3008 165.189L44.515 22.2112"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M123.477 142.864L128.35 166.283C128.649 167.727 129.934 168.758 131.405 168.736L147.608 168.495C149.512 168.466 150.932 166.731 150.584 164.861L150.044 161.911L149.774 160.46L149.561 159.323L123.023 15.0728C122.746 13.5797 121.431 12.5133 119.911 12.5559L48.1304 14.5253C46.2764 14.5751 44.8841 16.2459 45.1753 18.0873L68.8371 167.691C69.0786 169.213 70.407 170.322 71.9485 170.286L91.6678 169.824C93.5289 169.781 94.9212 168.111 94.6371 166.276L91.0995 143.525C90.9077 142.288 91.8454 141.165 93.0956 141.136L120.458 140.475C121.915 140.439 123.186 141.449 123.477 142.871V142.864Z"
          fill="white"
        />
        <path
          id="Vector_6"
          d="M126.844 159.074L128.343 166.284C128.641 167.727 129.927 168.758 131.397 168.736L147.6 168.495C149.504 168.466 150.925 166.731 150.577 164.862L149.767 160.461"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_7"
          d="M67.3359 158.164L68.8419 167.677C69.0834 169.198 70.4118 170.307 71.9532 170.272L91.6726 169.81C93.5337 169.767 94.926 168.096 94.6418 166.262L91.1043 143.511C90.9125 142.274 91.8501 141.15 93.1004 141.122L120.463 140.461C121.919 140.425 123.191 141.435 123.482 142.857L126.309 156.443"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_8"
          d="M124.209 21.5142L123.023 15.0728C122.746 13.5797 121.431 12.5133 119.911 12.5559L48.1304 14.5253C46.2764 14.5751 44.8841 16.2459 45.1753 18.0873L66.8908 155.377"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_9"
          d="M149.197 157.31L125.422 28.0974"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_10"
          d="M63.1444 47.4719L76.9181 132.085C77.0886 133.13 77.9978 133.891 79.0563 133.87L131.8 132.789C133.107 132.761 134.08 131.566 133.839 130.279L118.012 45.4528C117.82 44.429 116.911 43.6967 115.874 43.718L65.176 45.012C63.8903 45.0475 62.9313 46.2064 63.1373 47.4791L63.1444 47.4719Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_11"
          d="M72.7695 167.258L91.5086 166.98"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_12"
          d="M48.7266 17.1204L71.2944 164.108"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_13"
          d="M120.457 140.461L125.11 140.375C125.792 140.361 126.396 140.837 126.538 141.506L131.482 164.584C131.624 165.245 132.206 165.715 132.881 165.715H135.346"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_14"
          d="M65.4258 47.7632L79.4126 130.855"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_15"
          d="M70.0067 21.87L103.564 20.8462C104.899 20.8035 106.085 21.7065 106.405 23.0075L108.685 32.2289C109.119 33.9921 107.819 35.7056 106.007 35.7554L71.7613 36.701C70.3477 36.7365 69.1259 35.734 68.8843 34.3476L67.2932 25.1974C66.9948 23.491 68.2805 21.9198 70.0067 21.87Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_16"
          d="M67.2969 24.5644L102.949 23.3415C103.674 23.3202 104.313 23.8178 104.462 24.5359L106.53 34.6602"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_17"
          d="M79.2544 101.442L94.5269 66.8319C95.1804 65.346 97.1978 65.1256 98.1639 66.4267L123.14 100.212C124.163 101.592 123.197 103.554 121.485 103.589L81.2362 104.407C79.6877 104.436 78.6363 102.85 79.2615 101.435L79.2544 101.442Z"
          fill="#D85E41"
        />
        <path
          id="Vector_18"
          d="M82.3673 100.731L95.6508 69.9958C95.935 69.3346 96.83 69.228 97.2633 69.8039L119.384 99.4658C119.838 100.07 119.419 100.938 118.659 100.966L83.2623 102.04C82.5733 102.061 82.1044 101.364 82.3744 100.731H82.3673Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_19"
          d="M96.896 77.1196L98.7145 76.6574C99.1052 76.5579 99.503 76.8067 99.5882 77.2049L102.586 91.3676C102.685 91.8226 102.323 92.2492 101.861 92.235L99.0839 92.1354C98.7429 92.1212 98.4517 91.8652 98.4019 91.524L96.3561 77.923C96.2993 77.5604 96.5337 77.212 96.8889 77.1196H96.896Z"
          fill="white"
        />
        <path
          id="Vector_20"
          d="M99.4795 94.5247L102.946 94.5602C103.23 94.5602 103.465 94.7735 103.5 95.0579L103.713 96.814C103.756 97.1482 103.493 97.4468 103.159 97.4468H99.8276C99.5577 97.4539 99.3233 97.2619 99.2735 96.9989L98.9255 95.2001C98.8544 94.8517 99.1244 94.5247 99.4866 94.5318L99.4795 94.5247Z"
          fill="white"
        />
        <path
          id="Vector_21"
          d="M69.604 24.486L103.047 23.3484C103.672 23.3271 104.227 23.7466 104.383 24.358L106.755 33.8709C106.961 34.6956 106.343 35.5061 105.491 35.5204L71.252 36.2171C70.5985 36.2313 70.0302 35.7621 69.9166 35.1151L68.3396 26.0501C68.2046 25.2539 68.7942 24.5215 69.604 24.4931V24.486Z"
          fill="black"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_22"
          d="M126.793 8.78757C128.384 6.80394 129.975 4.8132 131.566 2.82958C131.914 2.39588 132.021 1.74178 131.566 1.32231C131.183 0.966818 130.43 0.860171 130.06 1.32231C128.469 3.30593 126.878 5.29667 125.287 7.28029C124.939 7.71399 124.832 8.36809 125.287 8.78757C125.67 9.14305 126.423 9.2497 126.793 8.78757Z"
          fill="#010205"
        />
        <path
          id="Vector_23"
          d="M122.206 6.97438C122.604 5.0974 123.002 3.22042 123.4 1.34344C123.513 0.795988 123.236 0.163218 122.654 0.0281328C122.121 -0.0927334 121.467 0.191657 121.34 0.774659L120.146 6.4056C120.033 6.95305 120.31 7.58582 120.892 7.7209C121.425 7.84177 122.078 7.55738 122.206 6.97438Z"
          fill="#010205"
        />
        <path
          id="Vector_24"
          d="M129.201 12.3357L135.822 10.3734C136.355 10.217 136.745 9.61973 136.568 9.05806C136.39 8.49639 135.829 8.1409 135.253 8.31154L128.633 10.2738C128.1 10.4302 127.71 11.0275 127.887 11.5891C128.065 12.1508 128.626 12.5063 129.201 12.3357Z"
          fill="#010205"
        />
      </g>
      <defs>
        <clipPath id="clip0_6067_9478">
          <rect
            width="196"
            height="198"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
