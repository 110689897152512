import { Column, useFormContext } from '@kamona/components';
import React, { FC, ReactNode } from 'react';
import { SubmitHandler, ValidationMode, ValidationRule } from 'react-hook-form';

import { useDeviceDetect } from '@/shared/hooks';

import { ButtonWithLoadingState } from '../button-with-loading-state';
import { PinInput } from '../otp-input';
import PasscodeInputWithKeypad from '../passcode-input-with-keypad/passcode-input-with-keypad';

export interface PasscodeInputWrapperProps {
  label: string;
  isButtonLoading: boolean;
  handleSubmit: SubmitHandler<Record<string, string>>;
  renderRecoverPasscodeLink?: () => ReactNode;
  passcodeLength: number;
  buttonDisabled: boolean;
  inputMasked: boolean;
  name: string;
  pattern: ValidationRule<RegExp>;
  error?: string;
  resetError?: () => void;
  onResend?: () => void;
  showValidationMessage?: boolean;
  pinInputVariant: 'filled' | 'outline';
  pinInputSize: 'small' | 'large' | 'medium';
  isPinInputClearable?: boolean;
  pinInputWrapperClassName?: string;
  pinInputValidationMode: keyof ValidationMode;
}

export const PasscodeInputWrapper: FC<PasscodeInputWrapperProps> = ({
  label,
  isButtonLoading,
  error,
  resetError,
  handleSubmit,
  renderRecoverPasscodeLink,
  passcodeLength,
  onResend,
  buttonDisabled,
  inputMasked,
  pattern,
  name,
  showValidationMessage,
  pinInputVariant,
  pinInputSize,
  isPinInputClearable,
  pinInputWrapperClassName,
  pinInputValidationMode,
}) => {
  const { watch } = useFormContext();
  const fieldValue = watch(name);
  const { isTabletOrMobile } = useDeviceDetect();
  return isTabletOrMobile ? (
    <PasscodeInputWithKeypad
      aria-label={name}
      length={passcodeLength}
      name={name}
      showValidationMessage={showValidationMessage}
      onSubmit={handleSubmit}
      renderRecoverPasscodeLink={renderRecoverPasscodeLink}
      keypadDisabled={isButtonLoading}
      errorText={error}
      resetErrorText={resetError}
      dotAppearance={error ? 'error' : 'default'}
      resetValue={!!error}
    />
  ) : (
    <Column
      gapY="units-unit40"
      className="flex-grow justify-between md:justify-start gap-y-units-unit32 md:gap-y-units-unit40"
    >
      <Column as="fieldset" gap="units-unit16">
        <PinInput
          aria-label={name}
          length={passcodeLength}
          name={name}
          variant={pinInputVariant}
          showValidationMessage={showValidationMessage}
          size={pinInputSize}
          label={label}
          onResend={onResend}
          isClearable={isPinInputClearable}
          masked={inputMasked}
          pattern={pattern}
          wrapperClassName={pinInputWrapperClassName}
          helpText={error}
          appearance={error ? 'error' : 'default'}
          resetErrorText={resetError}
          resetValue={!!error}
          validationMode={pinInputValidationMode}
        />
        {renderRecoverPasscodeLink?.()}
      </Column>
      <ButtonWithLoadingState
        disabled={buttonDisabled || fieldValue.length < passcodeLength}
        loading={isButtonLoading}
        size="medium"
        type="submit"
      >
        Continue
      </ButtonWithLoadingState>
    </Column>
  );
};
