import { Button, ButtonProps } from '@kamona/components';
import { createComponent } from '@kamona/styled-system';

import { KamonaSpinner } from '@/ui/svgs/kamona-spinner';

export interface ButtonWithLoadingStateProps extends ButtonProps {
  loading?: boolean;
}

export const ButtonWithLoadingState =
  createComponent<ButtonWithLoadingStateProps>(
    (
      { loading = false, children, disabled, size = 'large', ...props },
      ref,
    ) => {
      const spinnerSize = {
        large: 'w-units-unit24 h-units-unit24',
        medium: 'w-units-unit20 h-units-unit20',
        small: 'w-units-unit16 h-units-unit16',
        tiny: 'w-units-unit12 h-units-unit12',
      }[size];

      return (
        <Button ref={ref} size={size} disabled={disabled || loading} {...props}>
          {loading ? <KamonaSpinner className={spinnerSize} /> : children}
        </Button>
      );
    },
  );
