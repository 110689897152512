import { AlertModal, Header, Typography } from '@kamona/components';
import { isEmpty } from 'lodash';
import React, { FC, useEffect } from 'react';

import { AlertDialogThumbnail } from '@/ui/components/alert-dialog';
import { CautionDangerIllustration } from '@/ui/svgs/caution-danger-illustration';

import { useSigninFormConfigContext } from '../providers';

interface AccountLockedModalProps {
  recoverPasscodeFn: () => void;
}

export const AccountLockedModal: FC<Readonly<AccountLockedModalProps>> = ({
  recoverPasscodeFn,
}) => {
  const { config, setConfig } = useSigninFormConfigContext();

  const resetAccountLocked = () => {
    setConfig({ ...config, accountLocked: null });
  };

  useEffect(() => {
    return () => {
      resetAccountLocked();
    };
  }, []);

  const onAccept = () => {
    recoverPasscodeFn();
  };

  return (
    <AlertModal
      open={!isEmpty(config.accountLocked)}
      title={<Header weight={'bold'}>We&apos;ve locked your account</Header>}
      description={
        <Typography color={'content-subtext-default'} className="mt-1">
          {config?.accountLocked?.message}
        </Typography>
      }
      onAccept={onAccept}
      acceptLabel="Reset passcode"
      cancelLabel=""
      backdrop="normal"
      size="medium"
      type="neutral"
      centered
      dismissOnOutsideClick={false}
      dismissOnEscapeKeyPressed={false}
      dismissOnTriggerPressed={false}
      icon={
        <AlertDialogThumbnail
          wrapperClassName="!pt-units-unit0 !md:pt-units-unit0"
          alt="We've locked your account"
          illustration={CautionDangerIllustration}
          className="w-[118px] h-[119px]"
        />
      }
    />
  );
};
