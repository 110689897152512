import { useHttpMutation } from '@/shared/hooks';

import { InitTwoFaRequestPayload, TwoFaResponse } from '../../../shared/types';

export const useCreate2faSession = (
  options?: Parameters<
    typeof useHttpMutation<InitTwoFaRequestPayload, TwoFaResponse>
  >[0],
) => {
  return useHttpMutation<InitTwoFaRequestPayload, TwoFaResponse>({
    method: 'POST',
    // api: mockApi,
    // url: `/2fas`,
    url: `/identity/v1/2fas`,
    ...options,
  });
};
