'use client';

import {
  Avatar,
  Column,
  Form,
  FormState,
  LinkButton,
} from '@kamona/components';
import React, { FC, useEffect } from 'react';

import { PageSubtitle, PageTitle } from '@/ui/components/page';
import { PasscodeInputWrapper } from '@/ui/components/passcode-input-wrapper';

import { useDeviceDetect } from '@/shared/hooks';
import { ROUTES } from '@/shared/routes';
import { Profile } from '@/shared/types';
import { capitalizeString, getUserInitials } from '@/shared/utils';

import { Link } from '@/lib/navigation';
import { cn } from '@/lib/utils';

import { useSignInForm } from '../hooks/use-sign-in-form';
import { useInitRecoverPasswordForm } from '../passcode-recovery/hooks/use-init-recover-password-form';
import { useSigninFormConfigContext } from '../providers';
import { AccountLockedModal } from './account-locked-modal';

interface WelcomeBackFormProps {
  data: Partial<Profile>;
}
export const WelcomeBackForm: FC<WelcomeBackFormProps> = ({ data }) => {
  const { handleInitRecoverPasscode } = useInitRecoverPasswordForm();
  const usernameInitials = getUserInitials({
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
  });
  const { isTabletOrMobile, isMobile, isTablet } = useDeviceDetect();
  const { config, setConfig } = useSigninFormConfigContext();
  const { passcodeLength = 6 } = config.passcodeVerification;

  useEffect(() => {
    setConfig({
      ...config,
      data: {
        ...config.data,
        username: data.phone,
      },
    });
  }, []);

  const recoverPasscodeFn = () =>
    handleInitRecoverPasscode({ phoneNumber: data.phone });

  const { handleSignIn, isLoading, error, setError } = useSignInForm({
    passcodeLength,
  });

  const onSubmitHandler = (formValues: Record<string, any>) => {
    handleSignIn(formValues.passcode, data.phone);
  };

  const renderRecoverPasscodeLink = () => {
    return (
      <LinkButton
        aria-label="recover-passcode-link"
        onClick={recoverPasscodeFn}
        className={{
          root: cn(
            'font-medium text-body-default no-underline !justify-start !text-moniepoint-primary-l7 ',
          ),
        }}
      >
        Forgot your passcode?
      </LinkButton>
    );
  };

  const title = (
    <>
      Welcome back,
      {isMobile && <br />}{' '}
      <span className={`${isMobile ? 'truncate max-w-72 inline-block' : ''}`}>
        {capitalizeString(data?.firstName ?? '')}
      </span>
    </>
  );

  return (
    <>
      <Form
        onSubmit={onSubmitHandler}
        validationMode="onSubmit"
        className={cn('flex flex-grow flex-col justify-start', {
          'justify-between': isMobile,
          'justify-evenly': isTablet,
          'items-center': isTabletOrMobile,
          'md:gap-units-unit40': !isTabletOrMobile,
        })}
      >
        <Column
          className="gap-units-unit16 md:gap-units-unit40"
          align={isTabletOrMobile ? 'center' : 'start'}
        >
          <Avatar
            size="large"
            appearance="active"
            text={usernameInitials}
            shape="circle"
            position="left"
          />
          <PageTitle
            className="my-units-unit16"
            align={isTabletOrMobile ? 'center' : 'left'}
            text={title}
            subtitle={
              <PageSubtitle
                align={isTabletOrMobile ? 'center' : 'left'}
                color={'content-subtext-default'}
              >
                {!isTabletOrMobile && (
                  <>
                    Enter your secure 6-digit passcode.
                    <br />
                  </>
                )}
                Not {capitalizeString(data?.firstName ?? '')}?{' '}
                <Link
                  className={
                    'inline-block text-moniepoint-primary-l7 font-medium '
                  }
                  href={ROUTES.LOGOUT}
                >
                  Log out
                </Link>
              </PageSubtitle>
            }
          />
        </Column>
        <FormState>
          {(formState) => (
            <PasscodeInputWrapper
              label="Passcode"
              isButtonLoading={isLoading}
              handleSubmit={onSubmitHandler}
              passcodeLength={passcodeLength}
              buttonDisabled={!formState.isValid}
              inputMasked={true}
              name="passcode"
              pattern={{
                value: /^\d{1,6}$/,
                message: 'Enter 6 digit passcode',
              }}
              pinInputVariant={'filled'}
              pinInputSize={'large'}
              isPinInputClearable={false}
              pinInputWrapperClassName="gap-y-units-unit24"
              pinInputValidationMode={'onSubmit'}
              error={error}
              resetError={() => setError('')}
              renderRecoverPasscodeLink={renderRecoverPasscodeLink}
            />
          )}
        </FormState>
      </Form>
      <AccountLockedModal recoverPasscodeFn={recoverPasscodeFn} />
    </>
  );
};
